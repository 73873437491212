import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Card,
  Accordion,
  Spinner,
  Tabs,
  Tab,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAuth } from "../Contexts/MyContext";
import axios from "axios";
import { url, profit, discount } from "../../config";

const Configurator = () => {
  const { authContext, user, setUser } = useAuth();
  const [openKey, setOpenKey] = useState(["0"]);
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState("0");

  const [dbProcessors, setDbProcessors] = useState([]);
  const [dbRams, setDbRams] = useState([]);
  const [dbChipsets, setDbChipsets] = useState([]);
  const [dbGpus, setDbGpus] = useState([]);
  const [dbCoolers, setDbCoolers] = useState([]);
  const [dbStorages, setDbStorages] = useState([]);
  const [dbCabinets, setDbCabinets] = useState([]);
  const [dbPsus, setDbPsus] = useState([]);

  const [platforms, setPlatforms] = useState([]);
  const [gpuTypes, setGpuTypes] = useState([]);
  const [generations, setGenerations] = useState([]);
  const [processors, setProcessors] = useState([]);
  const [ramTypes, setRamTypes] = useState([]);
  const [ramRGBs, setRamRGBs] = useState([]);
  const rgbOption = [
    {
      id: "1",
      value: "Y",
      option: "Yes",
    },
    {
      id: "2",
      value: "N",
      option: "No",
    },
  ];
  const [ramSizes, setRamSizes] = useState([]);
  const [chipsets, setChipsets] = useState([]);
  const [gpuBrands, setGpuBrands] = useState([]);
  const [gpuModels, setGpuModels] = useState([]);
  const [coolers, setCoolers] = useState([]);
  const [primaryStorages, setPrimaryStorages] = useState([]);
  const [secondaryStorages, setSecondaryStorages] = useState([]);
  const [psus, setPsus] = useState([]);
  const [cabinetBrands, setCabinetBrands] = useState([]);
  const [cabinetModels, setCabinetModels] = useState([]);

  const [msg, setMsg] = useState("");

  const [pc, setPc] = useState({
    platform: "",
    gpuType: "",
    generation: "",
    processor: "",
    igpu: "",
    ramType: "",
    chipset: "",
    ramRGB: "",
    ramSize: "",
    gpuBrand: "",
    gpuModel: "",
    cooler: "",
    primaryStorage: "",
    secondaryStorage: "",
    psu: "",
    cabinetBrand: "",
    cabinetModel: "",
    price: 0,
    features: {
      processor: [],
      ram: [],
      chipset: [],
      gpu: [],
      cooler: [],
      psu: [],
      primaryStorage: [],
      secondaryStorage: [],
      cabinet: [],
    },
    tdp: {},
    benchmarks: {},
    connectivity: {},
    expansion: {},
    warranty: {},
  });

  useEffect(() => {
    setMsg("");
    if (pc.platform === "") {
      fetchAll();
    }
    if (pc.cabinetModel !== "") {
      fetchPrice();
    }
  }, [pc.cabinetModel]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (pc.price !== 0) window.scrollTo(0, 650);
    if (pc.platform === "") window.scrollTo(0, 0);
  }, [pc.price]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchAll = async () => {
    setLoading(true);
    try {
      const res = await axios.post(url + "/fetchAllComponents");
      setDbProcessors(res.data.processors);
      setDbRams(res.data.rams);
      setDbChipsets(res.data.chipsets);
      setDbGpus(res.data.gpus);
      setDbCoolers(res.data.coolers);
      setDbStorages(res.data.storages);
      setDbCabinets(res.data.cabinets);
      setDbPsus(res.data.psus);
      fetchPlatform(res.data.processors);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      window.scrollTo(0, 0);
    }
  };

  const fetchPlatform = (dbProcessors) => {
    setPlatforms(
      dbProcessors.filter((processor) => {
        return processor.type === "platform";
      })
    );
  };

  const changePlatform = (e) => {
    setPc({
      ...pc,
      platform: e.target.value,
      gpuType: "",
      generation: "",
      processor: "",
      igpu: "",
      ramType: "",
      chipset: "",
      ramRGB: "",
      ramSize: "",
      gpuBrand: "",
      gpuModel: "",
      cooler: "",
      primaryStorage: "",
      secondaryStorage: "",
      psu: "",
      cabinetBrand: "",
      cabinetModel: "",
      price: 0,
      features: {},
      tdp: {},
      benchmarks: {},
      connectivity: {},
      expansion: {},
      warranty: {},
    });
    setGpuTypes([]);
    setGenerations([]);
    setProcessors([]);
    setRamTypes([]);
    setRamRGBs([]);
    setRamSizes([]);
    setChipsets([]);
    setGpuBrands([]);
    setGpuModels([]);
    setCoolers([]);
    setPrimaryStorages([]);
    setSecondaryStorages([]);
    setPsus([]);
    setCabinetBrands([]);
    setCabinetModels([]);
    fetchGpuTypes(dbProcessors);
  };

  const fetchGpuTypes = (dbProcessors) => {
    setGpuTypes(
      dbProcessors.filter((processor) => {
        return processor.type === "gputype";
      })
    );
  };

  const changeGpuType = (e) => {
    setPc({
      ...pc,
      gpuType: e.target.value,
      generation: "",
      processor: "",
      igpu: "",
      ramType: "",
      chipset: "",
      ramRGB: "",
      ramSize: "",
      gpuBrand: "",
      gpuModel: "",
      cooler: "",
      primaryStorage: "",
      secondaryStorage: "",
      psu: "",
      cabinetBrand: "",
      cabinetModel: "",
      price: 0,
      features: {},
      tdp: {},
      benchmarks: {},
      connectivity: {},
      expansion: {},
      warranty: {},
    });
    setGenerations([]);
    setProcessors([]);
    setRamTypes([]);
    setChipsets([]);
    setRamRGBs([]);
    setRamSizes([]);
    setGpuBrands([]);
    setGpuModels([]);
    setCoolers([]);
    setPrimaryStorages([]);
    setSecondaryStorages([]);
    setPsus([]);
    setCabinetBrands([]);
    setCabinetModels([]);
    fetchGenerations(pc.platform, e.target.value);
  };

  const fetchGenerations = (platform, gpuType) => {
    let tempGenerations = [];
    dbProcessors
      .filter((processor) => {
        return (
          processor.type === "processor" &&
          processor.platform === platform &&
          processor.gputype === gpuType
        );
      })
      .forEach((processor) => {
        if (tempGenerations.indexOf(processor.series) < 0) {
          tempGenerations.push(processor.series);
        }
      });
    setGenerations(tempGenerations);
  };

  const changeGeneration = (e) => {
    setPc({
      ...pc,
      generation: e.target.value,
      processor: "",
      igpu: "",
      ramType: "",
      chipset: "",
      ramRGB: "",
      ramSize: "",
      gpuBrand: "",
      gpuModel: "",
      cooler: "",
      primaryStorage: "",
      secondaryStorage: "",
      psu: "",
      cabinetBrand: "",
      cabinetModel: "",
      price: 0,
      features: {},
      tdp: {},
      benchmarks: {},
      connectivity: {},
      expansion: {},
      warranty: {},
    });
    setProcessors([]);
    setRamTypes([]);
    setChipsets([]);
    setRamRGBs([]);
    setRamSizes([]);
    setGpuBrands([]);
    setGpuModels([]);
    setCoolers([]);
    setPrimaryStorages([]);
    setSecondaryStorages([]);
    setPsus([]);
    setCabinetBrands([]);
    setCabinetModels([]);
    fetchProcessors(pc.platform, pc.gpuType, e.target.value);
  };

  const fetchProcessors = (platform, gpuType, generation) => {
    setProcessors(
      dbProcessors.filter((processor) => {
        return (
          processor.platform === platform &&
          processor.gputype === gpuType &&
          processor.series === generation
        );
      })
    );
  };

  const changeProcessor = (e) => {
    const tempProcessor = dbProcessors.filter((pro) => {
      return pro.title === e.target.value;
    });
    setPc({
      ...pc,
      processor: e.target.value,
      igpu: tempProcessor[0].igpu,
      ramType: "",
      chipset: "",
      ramRGB: "",
      ramSize: "",
      gpuBrand: "",
      gpuModel: "",
      cooler: "",
      primaryStorage: "",
      secondaryStorage: "",
      psu: "",
      cabinetBrand: "",
      cabinetModel: "",
      price: 0,
      features: {
        processor: JSON.parse(tempProcessor[0].features),
        chipset: [],
        ram: [],
        gpu: [],
        cooler: [],
        primaryStorage: [],
        secondaryStorage: [],
        psu: [],
        cabinet: [],
      },
      tdp: { processorTdp: tempProcessor[0].maxtdp },
      benchmarks: {
        processorBenchmark: JSON.parse(tempProcessor[0].benchmark),
      },
      connectivity: {},
      expansion: {},
      warranty: { processor: JSON.parse(tempProcessor[0].warranty) },
    });
    setOpenKey("1");
    setRamTypes([]);
    setChipsets([]);
    setRamRGBs([]);
    setRamSizes([]);
    setGpuBrands([]);
    setGpuModels([]);
    setCoolers([]);
    setPrimaryStorages([]);
    setSecondaryStorages([]);
    setPsus([]);
    setCabinetBrands([]);
    setCabinetModels([]);
    fetchRamTypes(e.target.value);
  };

  const fetchRamTypes = (processor) => {
    let tempRamTypes = [];
    const tempProcessor = dbProcessors.filter((pro) => {
      return pro.title === processor;
    });

    dbChipsets
      .filter((chipset) => {
        return (
          chipset.chipsetPlatform === tempProcessor[0].socket &&
          chipset.chipsetTdp >= tempProcessor[0].maxtdp
        );
      })
      .forEach((chipset) => {
        if (tempRamTypes.indexOf(chipset.chipsetRamType) < 0) {
          tempRamTypes.push(chipset.chipsetRamType);
        }
      });
    setRamTypes(tempRamTypes);
  };

  const changeRamType = (e) => {
    setPc({
      ...pc,
      ramType: e.target.value,
      chipset: "",
      ramRGB: "",
      ramSize: "",
      gpuBrand: "",
      gpuModel: "",
      cooler: "",
      primaryStorage: "",
      secondaryStorage: "",
      psu: "",
      cabinetBrand: "",
      cabinetModel: "",
      price: 0,
      features: {
        ...pc.features,
        chipset: [],
        ram: [],
        gpu: [],
        cooler: [],
        primaryStorage: [],
        secondaryStorage: [],
        psu: [],
        cabinet: [],
      },
      tdp: { ...pc.tdp },
      benchmarks: { ...pc.benchmarks },
      connectivity: {},
      expansion: {},
      warranty: { ...pc.warranty },
    });
    setChipsets([]);
    setRamRGBs([]);
    setRamSizes([]);
    setGpuBrands([]);
    setGpuModels([]);
    setCoolers([]);
    setPrimaryStorages([]);
    setSecondaryStorages([]);
    setPsus([]);
    setCabinetBrands([]);
    setCabinetModels([]);
    fetchChipsets(e.target.value);
  };

  const fetchChipsets = (ramType) => {
    const tempProcessor = dbProcessors.filter((pro) => {
      return pro.title === pc.processor;
    });
    setChipsets(
      dbChipsets.filter((chipset) => {
        return (
          chipset.chipsetPlatform === tempProcessor[0].socket &&
          chipset.chipsetRamType === ramType &&
          chipset.chipsetTdp >= tempProcessor[0].maxtdp
        );
      })
    );
  };

  const changeChipset = (e) => {
    const tempChipset = dbChipsets.filter((chipset) => {
      return (
        chipset.chipsetName === e.target.value &&
        chipset.chipsetRamType === pc.ramType
      );
    });
    setPc({
      ...pc,
      chipset: e.target.value,
      ramRGB: "",
      ramSize: "",
      gpuBrand: "",
      gpuModel: "",
      cooler: "",
      primaryStorage: "",
      secondaryStorage: "",
      psu: "",
      cabinetBrand: "",
      cabinetModel: "",
      price: 0,
      features: {
        ...pc.features,
        chipset: JSON.parse(tempChipset[0].features),
        ram: [],
        gpu: [],
        cooler: [],
        primaryStorage: [],
        secondaryStorage: [],
        psu: [],
        cabinet: [],
      },
      tdp: { ...pc.tdp },
      benchmarks: { ...pc.benchmarks },
      connectivity: {
        chipsetConnectivity: JSON.parse(tempChipset[0].connectivity),
        chipsetDisplay: JSON.parse(tempChipset[0].displayout),
      },
      expansion: {
        ...pc.expansion,
        chispetRamSlots: tempChipset[0].chipsetRamSlots,
        chipsetM2Slots: tempChipset[0].chipsetM2Slots,
        chipsetSataPorts: tempChipset[0].chipsetSataPorts,
      },
      warranty: {
        ...pc.warranty,
        chipset: JSON.parse(tempChipset[0].warranty),
      },
    });
    setRamRGBs([]);
    setRamSizes([]);
    setGpuBrands([]);
    setGpuModels([]);
    setCoolers([]);
    setPrimaryStorages([]);
    setSecondaryStorages([]);
    setPsus([]);
    setCabinetBrands([]);
    setCabinetModels([]);
    setOpenKey("2");
    fetchRamRGBs();
  };

  const fetchRamRGBs = () => {
    setRamRGBs(rgbOption);
  };

  const changeRamRGB = (e) => {
    setPc({
      ...pc,
      ramRGB: e.target.value,
      ramSize: "",
      gpuBrand: "",
      gpuModel: "",
      cooler: "",
      primaryStorage: "",
      secondaryStorage: "",
      psu: "",
      cabinetBrand: "",
      cabinetModel: "",
      price: 0,
      features: {
        ...pc.features,
        ram: [],
        gpu: [],
        cooler: [],
        psu: [],
        primaryStorage: [],
        secondaryStorage: [],
        cabinet: [],
      },
      tdp: { ...pc.tdp },
      benchmarks: { ...pc.benchmarks },
      connectivity: { ...pc.connectivity },
      expansion: { ...pc.expansion },
      warranty: { ...pc.warranty },
    });
    setRamSizes([]);
    setGpuBrands([]);
    setGpuModels([]);
    setCoolers([]);
    setPrimaryStorages([]);
    setSecondaryStorages([]);
    setPsus([]);
    setCabinetBrands([]);
    setCabinetModels([]);
    fetchRamSizes(pc.expansion.chispetRamSlots, pc.ramType, e.target.value);
  };

  const fetchRamSizes = (chispetRamSlots, ramType, ramRGB) => {
    setRamSizes(
      dbRams.filter((ram) => {
        return (
          ram.ramType === ramType &&
          ram.ramRGB === ramRGB &&
          ram.ramCount <= chispetRamSlots
        );
      })
    );
  };

  const changeRamSize = (e) => {
    const tempRam = dbRams.filter((ram) => {
      return (
        ram.ramSize === e.target.value &&
        ram.ramRGB === pc.ramRGB &&
        ram.ramType === pc.ramType
      );
    });
    setPc({
      ...pc,
      ramSize: e.target.value,
      gpuBrand: "",
      gpuModel: "",
      cooler: "",
      primaryStorage: "",
      secondaryStorage: "",
      psu: "",
      cabinetBrand: "",
      cabinetModel: "",
      price: 0,
      features: {
        ...pc.features,
        ram: JSON.parse(tempRam[0].features),
        gpu: [],
        cooler: [],
        primaryStorage: [],
        secondaryStorage: [],
        psu: [],
        cabinet: [],
      },
      tdp: { ...pc.tdp },
      benchmarks: { ...pc.benchmarks },
      connectivity: { ...pc.connectivity },
      expansion: { ...pc.expansion, ramCount: tempRam[0].ramCount },
      warranty: { ...pc.warranty, ram: tempRam[0].warranty },
    });
    setGpuBrands([]);
    setGpuModels([]);
    setCoolers([]);
    setPrimaryStorages([]);
    setSecondaryStorages([]);
    setPsus([]);
    setCabinetBrands([]);
    setCabinetModels([]);
    setOpenKey("3");
    fetchGpuBrands(pc.gpuType);
  };

  const fetchGpuBrands = (gpuType) => {
    let tempGpuBrands = [];
    let filteredGpuBrands = [];
    dbGpus.forEach((gpu) => {
      if (tempGpuBrands.indexOf(gpu.gpubrand) < 0) {
        tempGpuBrands.push(gpu.gpubrand);
      }
    });

    if (gpuType === "Dedicated") {
      filteredGpuBrands = tempGpuBrands.filter((gpuBrand) => {
        return gpuBrand !== "In-Built";
      });
      setGpuBrands(filteredGpuBrands);
    } else {
      setGpuBrands(tempGpuBrands);
    }
  };

  const changeGpuBrand = (e) => {
    setPc({
      ...pc,
      gpuBrand: e.target.value,
      gpuModel: "",
      cooler: "",
      primaryStorage: "",
      secondaryStorage: "",
      psu: "",
      cabinetBrand: "",
      cabinetModel: "",
      price: 0,
      features: {
        ...pc.features,
        gpu: [],
        cooler: [],
        primaryStorage: [],
        secondaryStorage: [],
        psu: [],
        cabinet: [],
      },
      tdp: { ...pc.tdp },
      benchmarks: { ...pc.benchmarks },
      connectivity: {
        ...pc.connectivity,
      },
      expansion: {
        ...pc.expansion,
      },
      warranty: { ...pc.warranty },
    });
    setGpuModels([]);
    setCoolers([]);
    setPrimaryStorages([]);
    setSecondaryStorages([]);
    setPsus([]);
    setCabinetBrands([]);
    setCabinetModels([]);
    fetchGpuModels(e.target.value);
  };

  const fetchGpuModels = (gpuBrand) => {
    setGpuModels(
      dbGpus.filter((gpu) => {
        return gpu.gpubrand === gpuBrand;
      })
    );
  };

  const changeGpuModel = (e) => {
    const tempGpu = dbGpus.filter((gpu) => {
      return (
        gpu.gpumodel ===
        (pc.gpuBrand !== "In-Built" ? e.target.value : "In-Built")
      );
    });
    setPc({
      ...pc,
      gpuModel: e.target.value,
      cooler: "",
      primaryStorage: "",
      secondaryStorage: "",
      psu: "",
      cabinetBrand: "",
      cabinetModel: "",
      price: 0,
      features: {
        ...pc.features,
        gpu: JSON.parse(tempGpu[0].features),
        cooler: [],
        psu: [],
        primaryStorage: [],
        secondaryStorage: [],
        cabinet: [],
      },
      tdp: { ...pc.tdp, gpuTdp: tempGpu[0].maxtdp },
      benchmarks: {
        ...pc.benchmarks,
        gpuBenchmark: JSON.parse(tempGpu[0].benchmarks),
      },
      connectivity: {
        ...pc.connectivity,
        gpuDisplay: JSON.parse(tempGpu[0].displayout),
      },
      expansion: {
        ...pc.expansion,
      },
      warranty: { ...pc.warranty, gpu: tempGpu[0].warranty },
    });
    setCoolers([]);
    setPrimaryStorages([]);
    setSecondaryStorages([]);
    setPsus([]);
    setCabinetBrands([]);
    setCabinetModels([]);
    setOpenKey("4");
    fetchCoolers(pc.processor);
  };

  const fetchCoolers = (processor) => {
    const tempProcessor = dbProcessors.filter((pro) => {
      return pro.title === processor;
    });
    if (tempProcessor[0].cooler === "Box") {
      setCoolers(
        dbCoolers.filter((cooler) => {
          return cooler.maxtdp > pc.tdp.processorTdp;
        })
      );
    } else {
      setCoolers(
        dbCoolers.filter((cooler) => {
          return (
            cooler.coolername !== "Box Cooler" &&
            cooler.maxtdp >= pc.tdp.processorTdp
          );
        })
      );
    }
  };

  const changeCooler = (e) => {
    const tempCooler = dbCoolers.filter((cooler) => {
      return cooler.coolername === e.target.value;
    });
    setPc({
      ...pc,
      cooler: e.target.value,
      primaryStorage: "",
      secondaryStorage: "",
      psu: "",
      cabinetBrand: "",
      cabinetModel: "",
      price: 0,
      features: {
        ...pc.features,
        cooler: JSON.parse(tempCooler[0].features),
        psu: [],
        primaryStorage: [],
        secondaryStorage: [],
        cabinet: [],
      },
      tdp: { ...pc.tdp },
      benchmarks: { ...pc.benchmarks },
      connectivity: {
        ...pc.connectivity,
      },
      expansion: {
        ...pc.expansion,
      },
      warranty: { ...pc.warranty, cooler: tempCooler[0].warranty },
    });
    setPrimaryStorages([]);
    setSecondaryStorages([]);
    setPsus([]);
    setCabinetBrands([]);
    setCabinetModels([]);
    setOpenKey("5");
    fetchPrimaryStorage();
  };

  const fetchPrimaryStorage = () => {
    setPrimaryStorages(
      dbStorages.filter((storage) => {
        return (
          storage.storagename !== "Not Available" &&
          storage.storagetype === "m.2"
        );
      })
    );
  };

  const changePrimaryStorage = (e) => {
    const tempPrimaryStorage = dbStorages.filter((storage) => {
      return storage.storagename === e.target.value;
    });
    setPc({
      ...pc,
      primaryStorage: e.target.value,
      secondaryStorage: "",
      psu: "",
      cabinetBrand: "",
      cabinetModel: "",
      price: 0,
      features: {
        ...pc.features,
        psu: [],
        primaryStorage: JSON.parse(tempPrimaryStorage[0].features),
        secondaryStorage: [],
        cabinet: [],
      },
      tdp: { ...pc.tdp },
      benchmarks: { ...pc.benchmarks },
      connectivity: {
        ...pc.connectivity,
      },
      expansion: {
        ...pc.expansion,
        psPort: tempPrimaryStorage[0].storagetype,
      },
      warranty: {
        ...pc.warranty,
        primaryStorage: tempPrimaryStorage[0].warranty,
      },
    });
    setSecondaryStorages([]);
    setPsus([]);
    setCabinetBrands([]);
    setCabinetModels([]);
    fetchSecondaryStorage();
  };

  const fetchSecondaryStorage = () => {
    pc.expansion.chipsetM2Slots > 1
      ? setSecondaryStorages(dbStorages)
      : setSecondaryStorages(
          dbStorages.filter((storage) => {
            return storage.storagetype !== "m.2";
          })
        );
  };

  const changeSecondaryStorage = (e) => {
    const tempSecondaryStorage = dbStorages.filter((storage) => {
      return storage.storagename === e.target.value;
    });
    setPc({
      ...pc,
      secondaryStorage: e.target.value,
      psu: "",
      cabinetBrand: "",
      cabinetModel: "",
      price: 0,
      features: {
        ...pc.features,
        psu: [],
        secondaryStorage: JSON.parse(tempSecondaryStorage[0].features),
        cabinet: [],
      },
      tdp: { ...pc.tdp },
      benchmarks: { ...pc.benchmarks },
      connectivity: {
        ...pc.connectivity,
      },
      expansion: {
        ...pc.expansion,
        ssPort: tempSecondaryStorage[0].storagetype,
      },
      warranty: {
        ...pc.warranty,
        secondaryStorage: tempSecondaryStorage[0].warranty,
      },
    });
    setPsus([]);
    setCabinetBrands([]);
    setCabinetModels([]);
    setOpenKey("6");
    fetchPsu();
  };

  const fetchPsu = () => {
    setPsus(
      dbPsus.filter((psu) => {
        return psu.tdp > (pc.tdp.processorTdp + pc.tdp.gpuTdp) * 1.25 + 100;
      })
    );
  };

  const changePsu = (e) => {
    const tempPsu = dbPsus.filter((psu) => {
      return psu.psuname === e.target.value;
    });
    setPc({
      ...pc,
      psu: e.target.value,
      cabinetBrand: "",
      cabinetModel: "",
      price: 0,
      features: {
        ...pc.features,
        psu: JSON.parse(tempPsu[0].features),
        cabinet: [],
      },
      tdp: { ...pc.tdp },
      benchmarks: { ...pc.benchmarks },
      connectivity: {
        ...pc.connectivity,
      },
      expansion: {
        ...pc.expansion,
      },
      warranty: { ...pc.warranty, psu: tempPsu[0].warranty },
    });
    setCabinetBrands([]);
    setCabinetModels([]);
    setOpenKey("7");
    fetchCabinetBrands();
  };

  const fetchCabinetBrands = () => {
    let tempCabinetBrands = [];
    const tempChipset = dbChipsets.filter((chipset) => {
      return (
        chipset.chipsetName === pc.chipset &&
        chipset.chipsetRamType === pc.ramType
      );
    });
    const tempGpu = dbGpus.filter((gpu) => {
      return gpu.gpumodel === pc.gpuModel;
    });
    const tempCooler = dbCoolers.filter((cooler) => {
      return cooler.coolername === pc.cooler;
    });
    dbCabinets
      .filter((cabinet) => {
        return (
          cabinet.cabinetchipset >= tempChipset[0].chipsetSize &&
          cabinet.cabinetvga >= (tempGpu.length > 0 ? tempGpu[0].length : 0) &&
          cabinet.cabinetcooler >= tempCooler[0].coolersize
        );
      })
      .forEach((cabinet) => {
        if (tempCabinetBrands.indexOf(cabinet.cabinetbrand) < 0) {
          tempCabinetBrands.push(cabinet.cabinetbrand);
        }
      });
    setCabinetBrands(tempCabinetBrands);
  };

  const changeCabinetBrand = (e) => {
    setPc({
      ...pc,
      cabinetBrand: e.target.value,
      cabinetModel: "",
      price: 0,
      features: {
        ...pc.features,
        cabinet: [],
      },
      tdp: { ...pc.tdp },
      benchmarks: { ...pc.benchmarks },
      connectivity: {
        ...pc.connectivity,
      },
      expansion: {
        ...pc.expansion,
      },
      warranty: { ...pc.warranty },
    });
    setCabinetModels([]);
    fetchCabinetModels(e.target.value);
  };

  const fetchCabinetModels = (cabinetBrand) => {
    const tempChipset = dbChipsets.filter((chipset) => {
      return (
        chipset.chipsetName === pc.chipset &&
        chipset.chipsetRamType === pc.ramType
      );
    });
    const tempGpu = dbGpus.filter((gpu) => {
      return gpu.gpumodel === pc.gpuModel;
    });
    const tempCooler = dbCoolers.filter((cooler) => {
      return cooler.coolername === pc.cooler;
    });
    setCabinetModels(
      dbCabinets.filter((cabinet) => {
        return (
          cabinet.cabinetchipset >= tempChipset[0].chipsetSize &&
          cabinet.cabinetvga >= (tempGpu.length > 0 ? tempGpu[0].length : 0) &&
          cabinet.cabinetcooler >= tempCooler[0].coolersize &&
          cabinet.cabinetbrand === cabinetBrand
        );
      })
    );
  };

  const changeCabinetModel = (e) => {
    const tempCabinet = dbCabinets.filter((cabinet) => {
      return (
        cabinet.cabinetbrand === pc.cabinetBrand &&
        cabinet.cabinetmodel === e.target.value
      );
    });
    setPc({
      ...pc,
      cabinetModel: e.target.value,
      price: 0,
      features: {
        ...pc.features,
        cabinet: JSON.parse(tempCabinet[0].features),
      },
      tdp: { ...pc.tdp },
      benchmarks: { ...pc.benchmarks },
      connectivity: {
        ...pc.connectivity,
        cabinetConnectivity: JSON.parse(tempCabinet[0].connectivity),
      },
      expansion: {
        ...pc.expansion,
        cabinetSata: JSON.parse(tempCabinet[0].expansion),
      },
      warranty: { ...pc.warranty, cabinet: tempCabinet[0].warranty },
    });
    setOpenKey([]);
  };

  const fetchPrice = () => {
    let price = 0;
    dbProcessors
      .filter((processor) => {
        return processor.title === pc.processor;
      })
      .forEach((processor) => {
        price = price + processor.price;
      });
    dbRams
      .filter((ram) => {
        return (
          ram.ramSize === pc.ramSize &&
          ram.ramRGB === pc.ramRGB &&
          ram.ramType === pc.ramType
        );
      })
      .forEach((ram) => {
        price = price + ram.ramPrice;
      });
    dbChipsets
      .filter((chipset) => {
        return (
          chipset.chipsetName === pc.chipset &&
          chipset.chipsetRamType === pc.ramType
        );
      })
      .forEach((chipset) => {
        price = price + chipset.chipsetPrice;
      });
    dbGpus
      .filter((gpu) => {
        return gpu.gpumodel === pc.gpuModel;
      })
      .forEach((gpu) => {
        price = price + gpu.price;
      });
    dbCoolers
      .filter((cooler) => {
        return cooler.coolername === pc.cooler;
      })
      .forEach((cooler) => {
        price = price + cooler.price;
      });
    dbStorages
      .filter((storage) => {
        return storage.storagename === pc.primaryStorage;
      })
      .forEach((storage) => {
        price = price + storage.price;
      });
    dbStorages
      .filter((storage) => {
        return storage.storagename === pc.secondaryStorage;
      })
      .forEach((storage) => {
        price = price + storage.price;
      });
    dbPsus
      .filter((psu) => {
        return psu.psuname === pc.psu;
      })
      .forEach((psu) => {
        price = price + psu.price;
      });
    dbCabinets
      .filter((cabinet) => {
        return (
          cabinet.cabinetbrand === pc.cabinetBrand &&
          cabinet.cabinetmodel === pc.cabinetModel
        );
      })
      .forEach((cabinet) => {
        price = price + cabinet.price;
      });

    price = (1 + profit / 100) * price;

    setPc({ ...pc, price });
  };

  const sendToCart = (e) => {
    const itemShort =
      pc.processor +
      "/" +
      pc.chipset +
      "/" +
      pc.ramSize +
      " " +
      pc.ramType +
      (pc.ramRGB === "Y" ? " RGB " : "") +
      "RAM" +
      "/" +
      (pc.gpuBrand !== "In-Built" ? pc.gpuBrand + " " : "") +
      pc.gpuModel +
      "/" +
      pc.cooler +
      "/" +
      pc.psu +
      "/" +
      pc.primaryStorage +
      "" +
      (pc.secondaryStorage !== "Not Available"
        ? "+" + pc.secondaryStorage
        : "") +
      "/" +
      pc.cabinetBrand +
      " " +
      pc.cabinetModel;

    if (
      user.cartInfo.filter((i) => {
        return i.item === JSON.stringify(pc);
      }).length === 0
    ) {
      if (pc.price !== 0) {
        if (authContext != null) {
          addToCart(itemShort);
        } else {
          setMsg("Please login to add PC to cart!");
          setTimeout(() => {
            setMsg("");
          }, 2000);
        }
      } else {
        setMsg("Please complete the PC before adding to cart!");
        setTimeout(() => {
          setMsg("");
        }, 2000);
      }
    } else {
      setMsg("Item already added to cart!");
      setTimeout(() => {
        setMsg("");
      }, 2000);
    }
  };

  const addToCart = async (itemShort) => {
    try {
      const res = await axios.post(
        url + "/addToCart",
        {
          userid: user.userInfo[0].id,
          item: JSON.stringify(pc),
          itemType: "PC",
          itemShort: itemShort,
          qty: 1,
          stock: "Y",
        },
        { withCredentials: true }
      );
      setUser({ ...user, cartInfo: res.data });
      setMsg("Item added to cart!");
      setTimeout(() => {
        setMsg("");
      }, 2000);
    } catch (err) {
      console.log(err);
    }
  };

  const handleAccordion = (eventKey) => {
    const index = openKey.indexOf(eventKey);
    index === -1
      ? setOpenKey([...openKey, eventKey])
      : setOpenKey(
          openKey.filter(function (item) {
            return item !== eventKey;
          })
        );
  };

  const PCSpecCard = () => {
    return (
      <Container>
        <Col className="h4 mt-3 mb-5 text-center text-danger">PC Summary</Col>
        <Tabs
          id="faq-tabs"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-4"
          variant="pills"
          justify
          fill
        >
          <Tab eventKey={"0"} title="Overview" key={"0"}>
            <Card
              className="shadow-sm text-light "
              style={{ backgroundColor: "#151515" }}
            >
              <Row>
                <Col md={6}>
                  <Row>
                    <Card.Img
                      src={
                        "/cabinet/" +
                        pc.cabinetBrand +
                        "/" +
                        pc.cabinetBrand +
                        " " +
                        pc.cabinetModel +
                        ".png"
                      }
                      alt="PC"
                      className="img-fluid mt-4"
                    />
                  </Row>
                  <Col
                    className="text-secondary text-center mb-3"
                    style={{ fontSize: "0.7rem" }}
                  >
                    *Image For Reference Only*
                  </Col>
                  <Col className="text-secondary text-center mb-3">
                    <Link
                      to="tel:+91-6369933507"
                      className="link-danger  d-block"
                    >
                      For More Customizations: <br /> +91-6369933507
                    </Link>
                  </Col>
                </Col>
                <Col md={6}>
                  <Card.Body>
                    <h5 className="text-danger">Specifications</h5>
                    <ul>
                      <li className="mt-4">
                        Processor: {pc.processor} <br />
                        <span className="text-secondary">
                          ({pc.warranty.processor}-year warranty)
                        </span>
                      </li>
                      <li className="mt-2">
                        Motherboard: {pc.chipset}
                        <br />
                        <span className="text-secondary">
                          ({pc.warranty.chipset}-year warranty)
                        </span>
                      </li>
                      <li className="mt-2">
                        GPU:{" "}
                        {pc.gpuBrand !== "In-Built"
                          ? pc.gpuBrand + " " + pc.gpuModel
                          : pc.igpu}
                        <br />
                        <span className="text-secondary">
                          ({pc.warranty.gpu}-year warranty)
                        </span>
                      </li>
                      <li className="mt-2">
                        RAM: {pc.ramSize} {pc.ramType}{" "}
                        {pc.ramRGB === "Y" ? "RGB" : ""}
                        <br />
                        <span className="text-secondary">
                          (
                          {pc.warranty.ram === 99
                            ? "Limited Lifetime Warranty"
                            : pc.warranty.ram + "-year warranty"}
                          )
                        </span>
                      </li>
                      <li className="mt-2">
                        Cooler: {pc.cooler}
                        <br />
                        <span className="text-secondary">
                          ({pc.warranty.cooler}-year warranty)
                        </span>
                      </li>
                      <li className="mt-2">
                        PSU: {pc.psu}
                        <br />
                        <span className="text-secondary">
                          ({pc.warranty.psu}-year warranty)
                        </span>
                      </li>
                      <li className="mt-2">
                        Primary Storage: {pc.primaryStorage}
                        <br />
                        <span className="text-secondary">
                          ({pc.warranty.primaryStorage}-year warranty)
                        </span>
                      </li>
                      {pc.secondaryStorage !== "Not Available" ? (
                        <li>
                          Secondary Storage: {pc.secondaryStorage}
                          <br />
                          <span className="text-secondary">
                            ({pc.warranty.secondaryStorage}-year warranty)
                          </span>
                        </li>
                      ) : (
                        ""
                      )}
                      <li className="mt-2">
                        Cabinet: {pc.cabinetBrand} {pc.cabinetModel}
                        <br />
                        <span className="text-secondary">
                          ({pc.warranty.cabinet}-year warranty)
                        </span>
                      </li>
                      <li className="mt-2">
                        Max Power Consumption:{" "}
                        {((pc.tdp.processorTdp + pc.tdp.gpuTdp) * 1.25).toFixed(
                          0
                        )}
                        W
                      </li>
                    </ul>
                    <h5 className="text-danger">Connectivity</h5>
                    <ul>
                      <li className="mt-4">
                        USB ports:{" "}
                        {pc.connectivity.chipsetConnectivity.usb30 +
                          pc.connectivity.chipsetConnectivity.usb20 +
                          pc.connectivity.chipsetConnectivity.usb32a +
                          pc.connectivity.chipsetConnectivity.usb32c +
                          pc.connectivity.cabinetConnectivity.usb30 +
                          pc.connectivity.cabinetConnectivity.usb20 +
                          pc.connectivity.cabinetConnectivity.usb32a +
                          (pc.connectivity.chipsetConnectivity.tch > 0
                            ? pc.connectivity.cabinetConnectivity.usb32c
                            : 0)}{" "}
                        USB Ports
                        {pc.connectivity.chipsetConnectivity.usb32a +
                          pc.connectivity.cabinetConnectivity.usb32a >
                        0
                          ? " (" +
                            (pc.connectivity.chipsetConnectivity.usb32a +
                              pc.connectivity.cabinetConnectivity.usb32a) +
                            "x USB 3.2 Type-A)"
                          : ""}{" "}
                        {pc.connectivity.cabinetConnectivity.usb30 +
                          pc.connectivity.chipsetConnectivity.usb30 >
                        0
                          ? "(" +
                            (pc.connectivity.cabinetConnectivity.usb30 +
                              pc.connectivity.chipsetConnectivity.usb30) +
                            "x USB 3.0 Type-A)"
                          : ""}{" "}
                        {pc.connectivity.chipsetConnectivity.usb20 +
                          pc.connectivity.cabinetConnectivity.usb20 >
                        0
                          ? "(" +
                            (pc.connectivity.chipsetConnectivity.usb20 +
                              pc.connectivity.cabinetConnectivity.usb20) +
                            "x USB 2.0 Type-A)"
                          : ""}{" "}
                        {pc.connectivity.chipsetConnectivity.usb32c +
                          (pc.connectivity.chipsetConnectivity.tch > 0
                            ? pc.connectivity.cabinetConnectivity.usb32c
                            : 0) >
                        0
                          ? "(" +
                            (pc.connectivity.chipsetConnectivity.usb32c +
                              (pc.connectivity.chipsetConnectivity.tch > 0
                                ? pc.connectivity.cabinetConnectivity.usb32c
                                : 0)) +
                            "x USB 3.2 Type-C)"
                          : ""}
                        .
                      </li>
                      <li className="mt-2">
                        Display Support:{" "}
                        {pc.gpuBrand === "In-Built"
                          ? pc.connectivity.chipsetDisplay[0]
                          : pc.connectivity.gpuDisplay[0]}
                      </li>
                      <li className="mt-2">
                        WiFi:{" "}
                        {pc.connectivity.chipsetConnectivity.wifi
                          ? pc.connectivity.chipsetConnectivity.wifi
                          : "No WiFi Support."}
                      </li>
                      <li className="mt-2">
                        Bluetooth:{" "}
                        {pc.connectivity.chipsetConnectivity.bt
                          ? pc.connectivity.chipsetConnectivity.bt
                          : "No Bluetooth Support."}
                      </li>
                      <li className="mt-2">
                        LAN: {pc.connectivity.chipsetConnectivity.lan}
                      </li>
                      <li className="mt-2">
                        Audio: {pc.connectivity.chipsetConnectivity.audio}
                      </li>
                    </ul>
                    <h5 className="text-danger">Expansion availability</h5>
                    <ul>
                      {pc.expansion.chipsetM2Slots -
                        (pc.expansion.psPort === "m.2" ? 1 : 0) -
                        (pc.expansion.ssPort === "m.2" ? 1 : 0) >
                      0 ? (
                        <li className="mt-4">
                          m.2 devices:{" "}
                          {pc.expansion.chipsetM2Slots -
                            (pc.expansion.psPort === "m.2" ? 1 : 0) -
                            (pc.expansion.ssPort === "m.2" ? 1 : 0)}{" "}
                          slots available
                        </li>
                      ) : (
                        ""
                      )}
                      {(pc.expansion.chipsetSataPorts -
                        (pc.expansion.psPort === "sata" ? 1 : 0) -
                        (pc.expansion.ssPort === "sata" ? 1 : 0) >
                      pc.expansion.cabinetSata.sata25 +
                        pc.expansion.cabinetSata.sata35
                        ? pc.expansion.cabinetSata.sata25 +
                          pc.expansion.cabinetSata.sata35
                        : pc.expansion.chipsetSataPorts -
                          (pc.expansion.psPort === "sata" ? 1 : 0) -
                          (pc.expansion.ssPort === "sata" ? 1 : 0)) > 0 ? (
                        <li className="mt-2">
                          SATA devices: Upto{" "}
                          {pc.expansion.chipsetSataPorts -
                            (pc.expansion.psPort === "sata" ? 1 : 0) -
                            (pc.expansion.ssPort === "sata" ? 1 : 0) >
                          pc.expansion.cabinetSata.sata25 +
                            pc.expansion.cabinetSata.sata35
                            ? pc.expansion.cabinetSata.sata25 +
                              pc.expansion.cabinetSata.sata35
                            : pc.expansion.chipsetSataPorts -
                              (pc.expansion.psPort === "sata" ? 1 : 0) -
                              (pc.expansion.ssPort === "sata" ? 1 : 0)}{" "}
                          SATA devices
                        </li>
                      ) : (
                        ""
                      )}
                      {pc.expansion.chispetRamSlots - pc.expansion.ramCount >
                      0 ? (
                        <li className="mt-2">
                          RAM:{" "}
                          {pc.expansion.chispetRamSlots - pc.expansion.ramCount}{" "}
                          slots available
                        </li>
                      ) : (
                        ""
                      )}
                      <li className="mt-2">
                        Power Headroom Available:{" "}
                        {pc.psu.match(/^(\d+)[W]/)[1] -
                          (pc.tdp.processorTdp + pc.tdp.gpuTdp * 1.25).toFixed(
                            0
                          )}
                        {"W"}
                      </li>
                    </ul>
                  </Card.Body>
                </Col>
              </Row>
            </Card>
          </Tab>
          <Tab eventKey={"1"} title="Benchmarks" key={"1"}>
            <Card
              className="shadow-sm text-light "
              style={{ backgroundColor: "#151515" }}
            >
              <ul>
                <h5 className="text-danger mt-3">GPU Benchmarks</h5>
                <li className="mt-4">
                  3DMark Timespy:{" "}
                  {pc.gpuBrand !== "In-Built"
                    ? pc.benchmarks.gpuBenchmark.timespy
                    : pc.benchmarks.processorBenchmark.timespy}
                </li>
                <li className="mt-4">
                  Heaven's Benchmark:{" "}
                  {pc.gpuBrand !== "In-Built"
                    ? pc.benchmarks.gpuBenchmark.heaven
                    : pc.benchmarks.processorBenchmark.heaven}
                </li>
                <h5 className="text-danger mt-5">Processor Benchmarks</h5>
                <li className="mt-4">
                  Cinebench R23 Single Core:{" "}
                  {pc.benchmarks.processorBenchmark.cbsc}
                </li>
                <li className="mt-4">
                  Cinebench R23 Multi Core:{" "}
                  {pc.benchmarks.processorBenchmark.cbmc}
                </li>
                <li className="mt-4">
                  Geekbench 6 Single Core:{" "}
                  {pc.benchmarks.processorBenchmark.gbsc}
                </li>
                <li className="mt-4">
                  Geekbench 6 Multi Core:{" "}
                  {pc.benchmarks.processorBenchmark.gbmc}
                </li>
              </ul>
            </Card>
            <Col
              className="text-secondary text-center mb-3"
              style={{ fontSize: "0.7rem" }}
            >
              *Scores are for reference purpose only*
            </Col>
          </Tab>
          <Tab eventKey={"2"} title="Features" key={"2"}>
            {/* Features */}
            <Card
              className="shadow-sm text-light "
              style={{ backgroundColor: "#151515" }}
            >
              <Row>
                <Col lg={6}>
                  <ul>
                    <h5 className="text-danger mt-4">CPU</h5>
                    {pc.features.processor.map((feature) => {
                      return <li key={feature}>{feature}</li>;
                    })}
                    <h5 className="text-danger mt-4">Chipset</h5>
                    {pc.features.chipset.map((feature) => {
                      return <li key={feature}>{feature}</li>;
                    })}
                    <h5 className="text-danger mt-4">RAM</h5>
                    {pc.features.ram.map((feature) => {
                      return <li key={feature}>{feature}</li>;
                    })}
                    <h5 className="text-danger mt-4">GPU</h5>
                    {pc.features.gpu.map((feature) => {
                      return <li key={feature}>{feature}</li>;
                    })}
                  </ul>
                </Col>
                <Col lg={6}>
                  <ul>
                    <h5 className="text-danger mt-4">Cooler</h5>
                    {pc.features.cooler.map((feature) => {
                      return <li key={feature}>{feature}</li>;
                    })}
                    <h5 className="text-danger mt-4">Storage</h5>
                    {pc.features.primaryStorage.map((feature) => {
                      return <li key={feature}>{feature}</li>;
                    })}
                    {pc.secondaryStorage !== "Not Available"
                      ? pc.features.secondaryStorage.map((feature) => {
                          return <li key={feature}>{feature}</li>;
                        })
                      : ""}
                    <h5 className="text-danger mt-4">PSU</h5>
                    {pc.features.psu.map((feature) => {
                      return <li key={feature}>{feature}</li>;
                    })}
                    <h5 className="text-danger mt-4">Chassis</h5>
                    {pc.features.cabinet.map((feature) => {
                      return <li key={feature}>{feature}</li>;
                    })}
                  </ul>
                </Col>
              </Row>
            </Card>
          </Tab>
        </Tabs>
      </Container>
    );
  };

  return (
    <Container>
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          {/* React Bootstrap Spinner */}
          <Spinner animation="grow" variant="danger">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <Container>
          <Row className="justify-content-center my-5">
            <Col
              lg={8}
              xs={12}
              className="border border-dark rounded"
              style={{ backgroundColor: "#151515" }}
            >
              <h1 className="my-4 text-center text-danger">
                Build Your Own PC
              </h1>
              <Row className="justify-content-center mb-5">
                <Col md={8} lg={8} xs={12}>
                  <Card border="dark" style={{ backgroundColor: "#151515" }}>
                    <Card.Body>
                      <Form>
                        <Accordion
                          activeKey={openKey}
                          onSelect={handleAccordion}
                        >
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              {pc.processor ? (
                                <span className="text-light">
                                  Processor :{" "}
                                  <span className="text-danger">
                                    {pc.processor}
                                  </span>
                                </span>
                              ) : (
                                <span className="text-light">Processor</span>
                              )}
                            </Accordion.Header>
                            <Accordion.Body>
                              <Form.Group controlId="platform">
                                <Form.Label>Platform</Form.Label>
                                <Form.Control
                                  size="sm"
                                  className="mb-3 dark-select"
                                  as="select"
                                  value={pc.platform}
                                  onChange={changePlatform}
                                >
                                  <option hidden>Choose any one...</option>
                                  {platforms.map((ctr) => (
                                    <option key={ctr.idcomp} value={ctr.title}>
                                      {ctr.title}
                                    </option>
                                  ))}
                                </Form.Control>
                              </Form.Group>
                              <Form.Group controlId="gpuType">
                                <Form.Label>In-Built or Dedicated</Form.Label>
                                <Form.Control
                                  size="sm"
                                  className="mb-3 dark-select"
                                  as="select"
                                  value={pc.gpuType}
                                  onChange={changeGpuType}
                                >
                                  <option hidden>Choose any one...</option>
                                  {gpuTypes.map((ctr) => (
                                    <option key={ctr.idcomp} value={ctr.title}>
                                      {ctr.title}
                                    </option>
                                  ))}
                                </Form.Control>
                              </Form.Group>
                              <Form.Group controlId="generations">
                                <Form.Label>Processor Series</Form.Label>
                                <Form.Control
                                  size="sm"
                                  className="mb-3 dark-select"
                                  as="select"
                                  value={pc.generation}
                                  onChange={changeGeneration}
                                >
                                  <option hidden>Choose any one...</option>
                                  {generations.map((title, index) => (
                                    <option key={index} value={title}>
                                      {title}
                                    </option>
                                  ))}
                                </Form.Control>
                              </Form.Group>
                              <Form.Group controlId="processor">
                                <Form.Label>Processor Model</Form.Label>
                                <Form.Control
                                  size="sm"
                                  className="mb-3 dark-select"
                                  as="select"
                                  value={pc.processor}
                                  onChange={changeProcessor}
                                >
                                  <option hidden>Choose any one...</option>
                                  {processors.map((ctr) => (
                                    <option key={ctr.idcomp} value={ctr.title}>
                                      {ctr.title}
                                    </option>
                                  ))}
                                </Form.Control>
                              </Form.Group>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1">
                            <Accordion.Header>
                              {pc.chipset ? (
                                <span className="text-light">
                                  Chipset :{" "}
                                  <span className="text-danger">
                                    {pc.chipset}
                                  </span>
                                </span>
                              ) : (
                                <span className="text-light">Chipset</span>
                              )}
                            </Accordion.Header>
                            <Accordion.Body>
                              <Form.Group controlId="chipset">
                                <Form.Group controlId="ramType">
                                  <Form.Label>RAM Type</Form.Label>
                                  <Form.Control
                                    size="sm"
                                    className="mb-3 dark-select"
                                    as="select"
                                    value={pc.ramType}
                                    onChange={changeRamType}
                                  >
                                    <option hidden>Choose any one...</option>
                                    {ramTypes.map((title, index) => (
                                      <option key={index} value={title}>
                                        {title}
                                      </option>
                                    ))}
                                  </Form.Control>
                                </Form.Group>
                                <Form.Label>Chipset Model</Form.Label>
                                <Form.Control
                                  size="sm"
                                  className="mb-3 dark-select"
                                  as="select"
                                  value={pc.chipset}
                                  onChange={changeChipset}
                                >
                                  <option hidden>Choose any one...</option>
                                  {chipsets.map((ctr) => (
                                    <option
                                      key={ctr.idchipset}
                                      value={ctr.chipsetName}
                                    >
                                      {ctr.chipsetName}
                                    </option>
                                  ))}
                                </Form.Control>
                              </Form.Group>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="2">
                            <Accordion.Header>
                              {pc.ramSize ? (
                                <span className="text-light">
                                  RAM :{" "}
                                  <span className="text-danger">
                                    {pc.ramSize}
                                  </span>
                                </span>
                              ) : (
                                <span className="text-light">RAM</span>
                              )}
                            </Accordion.Header>
                            <Accordion.Body>
                              <Form.Group controlId="ramRGB">
                                <Form.Label>RAM RGB</Form.Label>
                                <Form.Control
                                  size="sm"
                                  className="mb-3 dark-select"
                                  as="select"
                                  value={pc.ramRGB}
                                  onChange={changeRamRGB}
                                >
                                  <option hidden>Choose any one...</option>
                                  {ramRGBs.map((ctr) => (
                                    <option key={ctr.id} value={ctr.value}>
                                      {ctr.option}
                                    </option>
                                  ))}
                                </Form.Control>
                              </Form.Group>
                              <Form.Group controlId="ramSize">
                                <Form.Label>RAM Size</Form.Label>
                                <Form.Control
                                  size="sm"
                                  className="mb-3 dark-select"
                                  as="select"
                                  value={pc.ramSize}
                                  onChange={changeRamSize}
                                >
                                  <option hidden>Choose any one...</option>
                                  {ramSizes.map((ctr) => (
                                    <option key={ctr.idram} value={ctr.ramSize}>
                                      {ctr.ramSize}
                                    </option>
                                  ))}
                                </Form.Control>
                              </Form.Group>
                            </Accordion.Body>
                          </Accordion.Item>

                          <Accordion.Item eventKey="3">
                            <Accordion.Header>
                              {pc.gpuModel ? (
                                <span className="text-light">
                                  GPU :{" "}
                                  <span className="text-danger">
                                    {pc.gpuBrand === "In-Built"
                                      ? pc.igpu
                                      : pc.gpuModel}
                                  </span>
                                </span>
                              ) : (
                                <span className="text-light">GPU</span>
                              )}
                            </Accordion.Header>
                            <Accordion.Body>
                              <Form.Group controlId="gpuBrand">
                                <Form.Label>GPU Brand</Form.Label>
                                <Form.Control
                                  size="sm"
                                  as="select"
                                  className="mb-3 dark-select"
                                  value={pc.gpuBrand}
                                  onChange={changeGpuBrand}
                                >
                                  <option hidden>Choose any one...</option>
                                  {gpuBrands.map((title, index) => (
                                    <option key={index} value={title}>
                                      {title}
                                    </option>
                                  ))}
                                </Form.Control>
                              </Form.Group>
                              <Form.Group controlId="gpuModel">
                                <Form.Label>GPU Model</Form.Label>
                                <Form.Control
                                  size="sm"
                                  className="mb-3 dark-select"
                                  as="select"
                                  value={pc.gpuModel}
                                  onChange={changeGpuModel}
                                >
                                  <option hidden>Choose any one...</option>
                                  {pc.gpuBrand !== "In-Built" ? (
                                    gpuModels.map((ctr) => (
                                      <option
                                        key={ctr.idgpu}
                                        value={ctr.gpumodel}
                                      >
                                        {ctr.gpumodel}
                                      </option>
                                    ))
                                  ) : (
                                    <option key={1} value={pc.igpu}>
                                      {pc.igpu}
                                    </option>
                                  )}
                                </Form.Control>
                              </Form.Group>
                            </Accordion.Body>
                          </Accordion.Item>

                          <Accordion.Item eventKey="4">
                            <Accordion.Header>
                              {pc.cooler ? (
                                <span className="text-light">
                                  Cooler :{" "}
                                  <span className="text-danger">
                                    {pc.cooler}
                                  </span>
                                </span>
                              ) : (
                                <span className="text-light">Cooler</span>
                              )}
                            </Accordion.Header>
                            <Accordion.Body>
                              <Form.Group controlId="cooler">
                                <Form.Label>Cooler Model</Form.Label>
                                <Form.Control
                                  size="sm"
                                  className="mb-3 dark-select"
                                  as="select"
                                  value={pc.cooler}
                                  onChange={changeCooler}
                                >
                                  <option hidden>Choose any one...</option>
                                  {coolers.map((ctr) => (
                                    <option
                                      key={ctr.idcooler}
                                      value={ctr.coolername}
                                    >
                                      {ctr.coolername}
                                    </option>
                                  ))}
                                </Form.Control>
                              </Form.Group>
                            </Accordion.Body>
                          </Accordion.Item>

                          <Accordion.Item eventKey="5">
                            <Accordion.Header>
                              {pc.primaryStorage || pc.secondaryStorage ? (
                                <span className="text-light">
                                  Storage :{" "}
                                  <span className="text-danger">
                                    {pc.primaryStorage ? pc.primaryStorage : ""}{" "}
                                    {pc.secondaryStorage === "" ||
                                    pc.secondaryStorage === "Not Available"
                                      ? ""
                                      : "+ " + pc.secondaryStorage}
                                  </span>
                                </span>
                              ) : (
                                <span className="text-light">Storage</span>
                              )}
                            </Accordion.Header>
                            <Accordion.Body>
                              <Form.Group controlId="primaryStorage">
                                <Form.Label>Primary Storage</Form.Label>
                                <Form.Control
                                  size="sm"
                                  className="mb-3 dark-select"
                                  as="select"
                                  value={pc.primaryStorage}
                                  onChange={changePrimaryStorage}
                                >
                                  <option hidden>Choose any one...</option>
                                  {primaryStorages.map((ctr, i) => (
                                    <option
                                      key={"primary" + i}
                                      value={ctr.storagename}
                                    >
                                      {ctr.storagename}
                                    </option>
                                  ))}
                                </Form.Control>
                              </Form.Group>
                              <Form.Group controlId="secondaryStorage">
                                <Form.Label>Secondary Storage</Form.Label>
                                <Form.Control
                                  size="sm"
                                  className="mb-3 dark-select"
                                  as="select"
                                  value={pc.secondaryStorage}
                                  onChange={changeSecondaryStorage}
                                >
                                  <option hidden>Choose any one...</option>
                                  {secondaryStorages.map((ctr, i) => (
                                    <option
                                      key={"secondary" + i}
                                      value={ctr.storagename}
                                    >
                                      {ctr.storagename}
                                    </option>
                                  ))}
                                </Form.Control>
                              </Form.Group>
                            </Accordion.Body>
                          </Accordion.Item>

                          <Accordion.Item eventKey="6">
                            <Accordion.Header>
                              {pc.psu ? (
                                <span className="text-light">
                                  Power Supply :{" "}
                                  <span className="text-danger">{pc.psu}</span>
                                </span>
                              ) : (
                                <span className="text-light">Power Supply</span>
                              )}
                            </Accordion.Header>
                            <Accordion.Body>
                              <Form.Group controlId="psu">
                                <Form.Label>PSU Model</Form.Label>
                                <Form.Control
                                  size="sm"
                                  className="mb-3 dark-select"
                                  as="select"
                                  value={pc.psu}
                                  onChange={changePsu}
                                >
                                  <option hidden>Choose any one...</option>
                                  {psus.map((ctr) => (
                                    <option key={ctr.idpsu} value={ctr.psuname}>
                                      {ctr.psuname}
                                    </option>
                                  ))}
                                </Form.Control>
                              </Form.Group>
                            </Accordion.Body>
                          </Accordion.Item>

                          <Accordion.Item eventKey="7">
                            <Accordion.Header>
                              {pc.cabinetModel ? (
                                <span className="text-light">
                                  Cabinet :{" "}
                                  <span className="text-danger">
                                    {pc.cabinetBrand} {pc.cabinetModel}
                                  </span>
                                </span>
                              ) : (
                                <span className="text-light">Cabinet</span>
                              )}
                            </Accordion.Header>
                            <Accordion.Body>
                              <Form.Group controlId="cabinetBrand">
                                <Form.Label>Cabinet Brand</Form.Label>
                                <Form.Control
                                  size="sm"
                                  className="mb-3 dark-select"
                                  as="select"
                                  value={pc.cabinetBrand}
                                  onChange={changeCabinetBrand}
                                >
                                  <option hidden>Choose any one...</option>
                                  {cabinetBrands.map((ctr, index) => (
                                    <option key={index} value={ctr}>
                                      {ctr}
                                    </option>
                                  ))}
                                </Form.Control>
                              </Form.Group>
                              <Form.Group controlId="cabinetModel">
                                <Form.Label>Cabinet Model</Form.Label>
                                <Form.Control
                                  className="mb-3 dark-select"
                                  size="sm"
                                  as="select"
                                  value={pc.cabinetModel}
                                  onChange={changeCabinetModel}
                                >
                                  <option hidden>Choose any one...</option>
                                  {cabinetModels.map((ctr, index) => (
                                    <option
                                      key={index}
                                      value={ctr.cabinetmodel}
                                    >
                                      {ctr.cabinetmodel}
                                    </option>
                                  ))}
                                </Form.Control>
                              </Form.Group>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </Form>
                    </Card.Body>
                  </Card>
                </Col>
                {pc.price > 0 ? (
                  <Row className="justify-content-center my-5">
                    <Col
                      xxl={12}
                      className="border p-3 border-dark rounded"
                      style={{ backgroundColor: "#151515" }}
                    >
                      <PCSpecCard />
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
                <Col xxl={12} className="text-danger mt-1 text-center">
                  <h1>
                    {pc.price > 0 ? (
                      <div>
                        <p style={{ margin: 0, fontSize: "18px" }}>
                          Price:{" "}
                          <span
                            className="text-secondary"
                            style={{
                              textDecoration: "line-through",
                              fontWeight: "normal",
                              marginRight: "8px",
                            }}
                          >
                            ₹{" "}
                            {new Intl.NumberFormat("en-IN").format(
                              (Math.round(pc.price / 500) * 500 - 10).toFixed(0)
                            )}
                            /-
                          </span>
                          <span
                            style={{
                              color: "#dc3545",
                              fontWeight: "normal",
                              fontSize: "21px",
                            }}
                          >
                            ₹{" "}
                            {new Intl.NumberFormat("en-IN").format(
                              (
                                (Math.round(pc.price / 500) * 500 - 10) *
                                (1 - discount / 100)
                              ).toFixed(0)
                            )}
                            /-
                          </span>
                        </p>
                        <p
                          style={{
                            margin: 10,
                            fontSize: "15px",
                            color: "#fff",
                            fontWeight: "500",
                          }}
                        >
                          Save ₹{" "}
                          <span style={{ fontSize: "1.3rem" }}>
                            {new Intl.NumberFormat("en-IN").format(
                              (
                                Math.round(pc.price / 500) * 500 -
                                10 -
                                (Math.round(pc.price / 500) * 500 - 10) *
                                  (1 - discount / 100)
                              ).toFixed(0)
                            )}
                          </span>{" "}
                          ({discount}% Off)
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                  </h1>
                </Col>
                <Col xxl={12} className="text-center text-danger mt-3">
                  {msg}
                </Col>
                <Col xxl={12} className="text-center mt-1">
                  <Button variant="danger" onClick={sendToCart}>
                    Add To Cart
                  </Button>
                </Col>
              </Row>
              <Row className="justify-content-center"></Row>
            </Col>
          </Row>
        </Container>
      )}
    </Container>
  );
};

export default Configurator;
